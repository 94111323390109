/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
//import jQuery from 'jquery'
//import'./Component.js';
//import'./Plugin.js';


/*import './Site'*/
import './vendor/babel-external-helpers/babel-external-helpers'
import './vendor/bootstrap/bootstrap.js'

$(function (){
    $('[data-toggle="tooltip"]').tooltip()
})

/*import './Component'
import './Plugin'
import './Base'
import './Config'

import './Section/Menubar'
import './Section/Sidebar'
import './Section/PageAside'
import './Section/menu'
import Site from './Site'

(function(document, window, $, Site) {
    'use strict';
    console.log(window);
    var Site = window.Site;
    console.log(Site);
    $(document).ready(function() {
        console.log(Site);
        Site.run();
    });


})(document, window, jQuery, Site);*/